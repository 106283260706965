.skill-matrix-select {
    max-width: 40px;
    margin: auto;
}

.teammember-skill-view {
    max-width: 700px;
    margin: auto;
}

.teammember-skill-view select {
    max-width: 300px;
    margin: auto;
    float: right;
}

.table {
    width: 100%;
    table-layout: fixed;
}

.table thead th:not(:first-child) {
    text-align: center;
}

.legend-item {
    padding: 10px;
    margin-right: 2px;
}