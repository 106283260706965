body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  width: fit-width;
  --main-color: #F15A24;
  --main-color-hover: #FDE7DF;
  --track-color: #ddd;
/* Default mark color*/
  --mark-color: #aaaaaa;
}


/* https://colorate.azurewebsites.net/Color/F15A24 */
/* TINT(WHITE) #F15A24,#F37143,#F58962,#F7A081,#F9B8A1,#FBCFC0,#FDE7DF */
/* SHADE(BLACK) #F15A24,#CE4D1E,#AC4019,#893314,#67260F,#44190A,#220C05 */
/* SATURATION #F19271,#F1845D,#F1764A,#F16837,#F15A24,#F14B10,#F13F00 */

/* Complementary #24BBF1 // TRIAD #5a24f1  #24f159*/