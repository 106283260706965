.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 40vh;
}

.logo-logged-in {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    background-color: transparent;
    opacity: 0.1;
}

.auth-container button.btn-link {
    padding: 0;
    margin: 0;
}