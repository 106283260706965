.accordion-header-icon {
  margin-right: 8px;
  transition: transform 0.3s;
}

.accordion-header-icon.rotate {
  transform: rotate(360deg);
}

.accordion-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #6c757d;
}

.capacity-overview {
  width: 100%;
  margin: auto;
  margin-top: 20px;
}

.team-row {
  width: 100%;
  text-align: left;
  border: none;
  margin-bottom: 2px;
  padding-top:15px;
  padding-left:15px;
  padding-right:15px;
  border-radius: 4px;
}

.teammate-row {
  background-color: #f8f9fa;
  width: 100%;
  text-align: left;
  border: 1px #6c757d;
  margin-bottom: 2px;
  padding-top:15px;
  padding-left:15px;
  padding-right:15px;
  border-radius: 4px;
}

.teammate-name {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 10px;
}
.working-day {
  padding-top: 5px;
}

.working-day-label {
  display: flex;
  float: unset;
}

.week {
  margin: auto;
  margin-top:20px;
}
.availability-total {
  color: #6c757d;
}
/* .availability-total {
  position: absolute;
  bottom: 5px;
  left: 83px;
} */