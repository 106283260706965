.hours-slider {
    /* max width of your slider */
    max-width: 200px;
    /* Optional: Only If you want to center your slider in the page */
    /* margin: auto; */
    margin-left: 120px;
  }
  
  .hours-slider-track {
    /* Top value to align your track to the center of your thumb */
    top: 8px;
    /* thickness of the track */
    height: 4px;
   /* default color of your track */
    background: var(--track-color);
  }
  
  .hours-slider-track.hours-slider-track-0 {
    /* color of the track before the thumb */
    background: var(--main-color);
  }

  .hours-slider-thumb {
    cursor: pointer;
    /*color for the thumb */
    background: var(--main-color);
    /* shape of the thumb: circle */
    width: 20px;
    height: 20px;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;
    color: white;
    /* text-align: center; */
    font-size: x-small;
    align-items:center;
    justify-content:center;
    display: flex;
  }
  
  .hours-slider-thumb:hover {
    box-shadow: 0 0 0 8px var(--main-color-hover);
  }
  
  .hours-slider-mark {
    cursor: pointer;
    top: 6px;
    width: 1.5px;
    height: 8px;
    background-color: var(--mark-color);
  }
  
  .hours-slider-mark.hours-slider-mark-before {
    background-color: var(--main-color);
  }
  
  .hours-slider-mark.hours-slider-mark-active {
    display: none;
  }