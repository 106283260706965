.CircularProgressbar {
    width: 100%;
    vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #F15A24;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: #d6d6d6;
    stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #F15A24;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
}
