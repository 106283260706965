.sprints-history {
    max-width: 380px;
    margin: auto;
}

.sprints-history .card {
    margin-top: 5px;
}

.sprint-summary h2 {
    margin-top: 20px;
}

.edit-sprint{
    max-width: 90%;
    margin: auto;
}

.edit-sprint>div {
    margin-bottom: 15px;
}

.edit-sprint input,
.edit-sprint textarea {
    float: right;
    max-width: 170px;
    margin-bottom: 5px;
}

.sprint-date {
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0px;
    right: 0px;
}

.sprintStats {
    margin: auto;
}

.sprint-velocity-chart {
    float: left;
    margin-right: 30px;
    max-width: 200px;
    text-align: center;
}

.sprint-velocity-chart h1 {
    margin-top: 20px;
    background-color: #F894B0;
    color: #6c757d;
    padding-top: 20px;
    padding-bottom: 20px;
}

.sprint-commitment-chart {
    max-width: 90px;
    top: 40px;
    text-align: center;

}

.sprint-commitment-total {
    float: right;
    text-align: center;
}

.sprint-commitment-total span,
.sprint-velocity-chart span {
    margin: 0;
    font-size: small;
    color: #6c757d;
    position: relative;
}

.sprint-commitment-total .CircularProgressbar .CircularProgressbar-path {
    stroke: #F69B7B;
}

.sprint-commitment-total .CircularProgressbar .CircularProgressbar-text {
    fill: #F69B7B;
}

.edit-sprint-actions {
    margin-top: 15px;
    float: right;
}

.sprint-goal-achieved {
    margin-top: 42px;
}

.sprint-goal-achieved span {
    min-height: 25px;
    vertical-align: middle;
    float: right;
}

.sprint-goal-achieved input {
    display: inline-block;
    vertical-align:middle;
    float: left;
    min-height: 25px;
    margin: 0;
    padding: 0;
    width: 15px;
}